import glslScale from '@/libs/lygia/space/scale.glsl';

export const uniforms = () => {
    return {
        tMatcap: { value: null },
        u_matcapRotation: { value: 0 },
        u_matcapScale: { value: 1 },
        u_matcapRotationWorld: { value: 0 },
    };
};
export const shaderChunks = {
    uniforms: /* glsl */ `
        uniform sampler2D tMatcap;
        uniform float u_matcapRotation;
        uniform float u_matcapScale;
        uniform float u_matcapRotationWorld;
    `,
    funcs: /* glsl */ `
        ${glslScale}
        #ifndef FNC_MATCAP
        #define FNC_MATCAP
            vec3 matcap(sampler2D matcapTex, vec3 normal, vec3 viewPosition, bool isMask, float mask, float alpha, float calpha, float rot, float viewPositionScale) {
                viewPosition.xy = scale(viewPosition.xy, viewPositionScale, vec2(0.));
                
                vec3 viewDir = normalize( viewPosition );
                vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
                vec3 y = cross( viewDir, x );
                
                vec2 uv = vec2( dot( x, normal ), dot( y, normal ) );
                uv = mat2( cos(rot), sin(rot), -sin(rot), cos(rot) ) * uv;
                uv = uv * 0.495 + 0.5;
                
                vec4 c = texture2D( matcapTex, uv );
                float lalpha = alpha * mask;
                calpha = mix(lalpha / clamp(lalpha, 0.00001, 1.0), lalpha, float(isMask));
                return c.rgb;
            }

            vec3 matcap(sampler2D matcapTex, vec3 normal, vec3 viewPosition, bool isMask, float mask, float alpha, float calpha, float rot) {
                return matcap(matcapTex, normal, viewPosition, isMask, mask, alpha, calpha, rot, 1.0);
            }
        #endif
    `,
};
